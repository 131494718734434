import React from "react"
import {TFunction} from 'react-i18next'

import FormatTableTitle from "../components/FormatTableTitle"
import {BreakdownTableColumn} from './BreakdownTable.columns'


export default function ProductionTrackingSummaryColumns(t: TFunction): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t('component.ProductionTrackingSummary.Columns.descriptionHeader'),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => <FormatTableTitle>{params.value}</FormatTableTitle>,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "current_amount",
      headerName: '',
      flex: 1,
      minWidth: 50,
      sortable: false,
      renderCell: (params: any) => {
        return `${params.value} /`
      },
    },
    {
      align: "left",
      headerAlign: "left",
      field: "total_amount",
      headerName: '',
      flex: 1,
      minWidth: 50,
      sortable: false,
      renderCell: (params: any) => {
        return `${params.value} ${params.unit}`
      },
    },
  ]
}
