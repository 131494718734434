import {ActionCode, BulletinActionCode, ChangeOrderActionCode, EstimateActionCode} from '../types/action'
import theme from '../theme'

const draftFill = theme.palette.grey[600]
const approveFill = "#45bf55"
const reviseFill = "#b96aba"
const rejectFill = "#d92929"
const submittedFill = "#cd9534"

// ticket only
const tmSubmittedFill = "#f2c746"
const tmApproveFill = "#6aacba"
const costReviewedFill = submittedFill
const coAttachedFill = '#9BA4B9'
const statusSummaryTmRejectFill = '#F96666'
const statusSummaryTmReviseFill = '#e798ff'
const statusSummaryCostSubmittedFill = '#ffde9f'
const statusSummaryCostReviewedFill = '#ffc24e'
const statusSummaryCostOwnerFill = submittedFill

// estimate only
const pendingFill = theme.palette.grey[400]
const estimateSubmittedFill = "#f2c746"
const estimateTmAuthorizedFill = '#9BA4B9'
const estimateNotNeededFill = '#FFFFFF'
const estimateNotNeededStatusFill = theme.palette.grey[200]

// bulletin only
const bulletinReviewFill = "#f2c746"
const bulletinOwnerApprovedFill = '#9BA4B9'
const bulletinNotNeededFill = '#FFFFFF'
const bulletinNotNeededStatusFill = theme.palette.grey[200]

export function getTicketActionFill(action: ActionCode, coAttached: boolean): string {
  if (coAttached) return coAttachedFill
  return action === 'draft'
    ? draftFill
    : action === 'tm_approve'
    ? tmApproveFill
    : action === 'tm_reject'
    ? rejectFill
    : action === 'tm_revise'
    ? reviseFill
    : action === 'tm_submitted'
    ? tmSubmittedFill
    : action === 'cost_submitted'
    ? submittedFill
    : action === 'cost_reject'
    ? rejectFill
    : action === 'cost_revise'
    ? reviseFill
    : action === 'cost_reviewed'
    ? costReviewedFill
    : action === 'cost_owner'
    ? costReviewedFill
    : action === 'cost_approve'
    ? approveFill
    : draftFill
}

// pie chart uses different colors so that pieces of pie with same status color are more distinguishable
export function getTicketStatusSummaryChartFill(action: ActionCode, coAttached: boolean): string {
  if (coAttached) return coAttachedFill
  return action === 'draft'
    ? draftFill
    : action === 'tm_approve'
    ? tmApproveFill
    : action === 'tm_reject'
    ? statusSummaryTmRejectFill
    : action === 'tm_revise'
    ? statusSummaryTmReviseFill
    : action === 'tm_submitted'
    ? tmSubmittedFill
    : action === 'cost_submitted'
    ? statusSummaryCostSubmittedFill
    : action === 'cost_reject'
    ? rejectFill
    : action === 'cost_revise'
    ? reviseFill
    : action === 'cost_reviewed'
    ? statusSummaryCostReviewedFill
    : action === 'cost_owner'
    ? statusSummaryCostOwnerFill
    : action === 'cost_approve'
    ? approveFill
    : draftFill
}

export function getChangeOrderActionFill(action: ChangeOrderActionCode): string {
  return action === 'draft'
    ? draftFill
    : action === 'approve'
    ? approveFill
    : action === 'revise'
    ? reviseFill
    : action === 'submitted'
    ? submittedFill
    : draftFill
}

export function getEstimateActionFill(action: EstimateActionCode): string {
  return action === 'draft'
    ? draftFill
    : action === 'pending'
    ? draftFill
    : action === 'revise'
    ? reviseFill
    : action === 'submitted'
    ? estimateSubmittedFill
    : action === 'gc_approved'
    ? approveFill
    : action === 'tm_authorized'
    ? estimateTmAuthorizedFill
    : action === 'not_needed'
    ? estimateNotNeededFill
    : draftFill
}

export function getEstimateStatusFill(action: EstimateActionCode): string {
  // only different for pending and not needed
  if (action === 'pending') return pendingFill
  if (action === 'not_needed') return estimateNotNeededStatusFill
  return getEstimateActionFill(action)
}

export function getBulletinActionFill(action: BulletinActionCode): string {
  return action === 'draft'
    ? draftFill
    : action === 'review'
    ? bulletinReviewFill
    : action === 'estimates_approved'
    ? approveFill
    : action === 'owner_approved'
    ? bulletinOwnerApprovedFill
    : action === 'not_needed'
    ? bulletinNotNeededFill
    : draftFill
}

export function getBulletinStatusFill(action: BulletinActionCode): string {
  // only different for not needed
  if (action === 'not_needed') return bulletinNotNeededStatusFill
  return getBulletinActionFill(action)
}
